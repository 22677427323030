const CREATE_TASK_ENDPOINT =
  "https://lazzard.planfix.ru/webhook/json/2iza-exf1-s14s-0yyf";

export function createTask({
  inputText,
  inputProduct,
  inputEmail,
  inputPhone,
  inputName,
  selectedFonts,
}) {
  const data = {
    title: `Заявка с формы ТЕКСТ от ${inputEmail}`,
    name: inputName,
    phone: inputPhone,
    email: inputEmail,
    product: inputProduct,
    text: inputText,
    description: `
        name: ${inputName}<br>
        phone: ${inputPhone}<br>
        email: ${inputEmail}<br>
        product: ${inputProduct}<br>
        text: ${inputText}<br>
        fonts: ${selectedFonts.map((f) => f.name).join(", ")}
    `,
  };

  fetch(CREATE_TASK_ENDPOINT, {
    method: "POST",
    // mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch(console.log);
}
