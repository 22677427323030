export const fontTypes = [
  { id: "serif", title: "С засечками", fontName: "BaskervilleBT-Roman" },
  { id: "sans-serif", title: "Без засечек", fontName: "AvenirNextCyr-Medium" },
  { id: "handwritten", title: "Рукописный", fontName: "Adventure" },
  { id: "gothic", title: "Готический", fontName: "CyrillicGoth" },
  { id: "etnica", title: "Этнический", fontName: "IzhitsaCyrillic" },
  { id: "decor", title: "Декоративный", fontName: "AmaticSC-Bold" },
];

const decorFonts = [
  {
    name: "AmaticSC-Bold.woff2",
    path: "decor/AmaticSC-Bold.woff2",
    types: ["decor"],
  },
  {
    name: "AmaticSC-Regular.woff2",
    path: "decor/AmaticSC-Regular.woff2",
    types: ["decor"],
  },
  { name: "AnnaCTT.woff2", path: "decor/AnnaCTT.woff2", types: ["decor"] },
  {
    name: "AStamperBold.woff2",
    path: "decor/AStamperBold.woff2",
    types: ["decor"],
  },
  {
    name: "AStamperRgBt.woff2",
    path: "decor/AStamperRgBt.woff2",
    types: ["decor"],
  },
  {
    name: "AzerothCyrillic.woff2",
    path: "decor/AzerothCyrillic.woff2",
    types: ["decor"],
  },
  {
    name: "Bankir-Retro.woff2",
    path: "decor/Bankir-Retro.woff2",
    types: ["decor"],
  },
  {
    name: "BatmanForever.woff2",
    path: "decor/BatmanForever.woff2",
    types: ["decor"],
  },
  { name: "Charming.woff2", path: "decor/Charming.woff2", types: ["decor"] },
  {
    name: "CyrillicUniversity.woff2",
    path: "decor/CyrillicUniversity.woff2",
    types: ["decor"],
  },
  {
    name: "DSJapanCyr-Normal.woff2",
    path: "decor/DSJapanCyr-Normal.woff2",
    types: ["decor"],
  },
  { name: "Epson1.woff2", path: "decor/Epson1.woff2", types: ["decor"] },
  { name: "Etna.woff2", path: "decor/Etna.woff2", types: ["decor"] },
  {
    name: "Frenchpress.woff2",
    path: "decor/Frenchpress.woff2",
    types: ["decor"],
  },
  {
    name: "GameofThronesKG.woff2",
    path: "decor/GameofThronesKG.woff2",
    types: ["decor"],
  },
  { name: "Geisha.woff2", path: "decor/Geisha.woff2", types: ["decor"] },
  {
    name: "KatonaII-UltraLight.woff2",
    path: "decor/KatonaII-UltraLight.woff2",
    types: ["decor"],
  },
  {
    name: "KonstruktorRegular.woff2",
    path: "decor/KonstruktorRegular.woff2",
    types: ["decor"],
  },
  { name: "Lena.woff2", path: "decor/Lena.woff2", types: ["decor"] },
  { name: "Marske.woff2", path: "decor/Marske.woff2", types: ["decor"] },
  { name: "Moloko.woff2", path: "decor/Moloko.woff2", types: ["decor"] },
  { name: "Monotxt.woff2", path: "decor/Monotxt.woff2", types: ["decor"] },
  { name: "Moonstone.woff2", path: "decor/Moonstone.woff2", types: ["decor"] },
  { name: "mr_BenzaG.woff2", path: "decor/mr_BenzaG.woff2", types: ["decor"] },
  { name: "mr_FloraG.woff2", path: "decor/mr_FloraG.woff2", types: ["decor"] },
  {
    name: "PFFuelPro-Regular.woff2",
    path: "decor/PFFuelPro-Regular.woff2",
    types: ["decor"],
  },
  { name: "Pilotka.woff2", path: "decor/Pilotka.woff2", types: ["decor"] },
  { name: "Pomidorko.woff2", path: "decor/Pomidorko.woff2", types: ["decor"] },
  {
    name: "SonySketchEF.woff2",
    path: "decor/SonySketchEF.woff2",
    types: ["decor"],
  },
];

const etnicaFonts = [
  {
    name: "Agatha-Modern.woff2",
    path: "etnica/Agatha-Modern.woff2",
    types: ["etnica"],
  },
  { name: "ALaRuss.woff2", path: "etnica/ALaRuss.woff2", types: ["etnica"] },
  {
    name: "AsylbekM01kz.woff2",
    path: "etnica/AsylbekM01kz.woff2",
    types: ["etnica"],
  },
  {
    name: "BLAGOVEST_2.woff2",
    path: "etnica/BLAGOVEST_2.woff2",
    types: ["etnica"],
  },
  {
    name: "BonzaiRegular.woff2",
    path: "etnica/BonzaiRegular.woff2",
    types: ["etnica"],
  },
  {
    name: "CyrillicOld-Bold.woff2",
    path: "etnica/CyrillicOld-Bold.woff2",
    types: ["etnica"],
  },
  { name: "DSArabic.woff2", path: "etnica/DSArabic.woff2", types: ["etnica"] },
  { name: "DSGreece.woff2", path: "etnica/DSGreece.woff2", types: ["etnica"] },
  {
    name: "DSYermak_D.woff2",
    path: "etnica/DSYermak_D.woff2",
    types: ["etnica"],
  },
  { name: "Eh_cyr.woff2", path: "etnica/Eh_cyr.woff2", types: ["etnica"] },
  {
    name: "Evangelie-Ucs.woff2",
    path: "etnica/Evangelie-Ucs.woff2",
    types: ["etnica"],
  },
  {
    name: "Fita_Poluustav.woff2",
    path: "etnica/Fita_Poluustav.woff2",
    types: ["etnica"],
  },
  {
    name: "Fita_Vjaz.woff2",
    path: "etnica/Fita_Vjaz.woff2",
    types: ["etnica"],
  },
  {
    name: "GangofThreeRUSbyDaymarius.woff2",
    path: "etnica/GangofThreeRUSbyDaymarius.woff2",
    types: ["etnica"],
  },
  { name: "HanZi.woff2", path: "etnica/HanZi.woff2", types: ["etnica"] },
  {
    name: "Irmologion-ieUcs-SpacedOut.woff2",
    path: "etnica/Irmologion-ieUcs-SpacedOut.woff2",
    types: ["etnica"],
  },
  {
    name: "IzhitsaCyrillic.woff2",
    path: "etnica/IzhitsaCyrillic.woff2",
    types: ["etnica"],
  },
  {
    name: "MandarinC.woff2",
    path: "etnica/MandarinC.woff2",
    types: ["etnica"],
  },
  {
    name: "mr_ReaverockG.woff2",
    path: "etnica/mr_ReaverockG.woff2",
    types: ["etnica"],
  },
  { name: "Nuriakz.woff2", path: "etnica/Nuriakz.woff2", types: ["etnica"] },
  {
    name: "OliettascriptLyrica-BoldItalic.woff2",
    path: "etnica/OliettascriptLyrica-BoldItalic.woff2",
    types: ["etnica"],
  },
  {
    name: "Pehlevi-Regular.woff2",
    path: "etnica/Pehlevi-Regular.woff2",
    types: ["etnica"],
  },
  {
    name: "PFHellenicaSerifPro-Bold.woff2",
    path: "etnica/PFHellenicaSerifPro-Bold.woff2",
    types: ["etnica"],
  },
  {
    name: "PresentScript_cyr.woff2",
    path: "etnica/PresentScript_cyr.woff2",
    types: ["etnica"],
  },
  {
    name: "Rodchenko-Condensed.woff2",
    path: "etnica/Rodchenko-Condensed.woff2",
    types: ["etnica"],
  },
  {
    name: "RodchenkoC.woff2",
    path: "etnica/RodchenkoC.woff2",
    types: ["etnica"],
  },
  { name: "Runic.woff2", path: "etnica/Runic.woff2", types: ["etnica"] },
  {
    name: "RunicAltNo.woff2",
    path: "etnica/RunicAltNo.woff2",
    types: ["etnica"],
  },
  {
    name: "Slavjanic.woff2",
    path: "etnica/Slavjanic.woff2",
    types: ["etnica"],
  },
];

const gothicFonts = [
  {
    name: "CyrillicGoth.woff2",
    path: "gothic/CyrillicGoth.woff2",
    types: ["gothic"],
  },
  {
    name: "DeutschGothic.woff2",
    path: "gothic/DeutschGothic.woff2",
    types: ["gothic"],
  },
  { name: "GothicE.woff2", path: "gothic/GothicE.woff2", types: ["gothic"] },
  { name: "GothicG.woff2", path: "gothic/GothicG.woff2", types: ["gothic"] },
  { name: "GothicI.woff2", path: "gothic/GothicI.woff2", types: ["gothic"] },
  {
    name: "GothicRusMedium.woff2",
    path: "gothic/GothicRusMedium.woff2",
    types: ["gothic"],
  },
  {
    name: "LombardinaTwo.woff2",
    path: "gothic/LombardinaTwo.woff2",
    types: ["gothic"],
  },
  {
    name: "PFStampsPro-Metal.woff2",
    path: "gothic/PFStampsPro-Metal.woff2",
    types: ["gothic"],
  },
  {
    name: "Rurintania.woff2",
    path: "gothic/Rurintania.woff2",
    types: ["gothic"],
  },
  {
    name: "Schwabacher-Regular.woff2",
    path: "gothic/Schwabacher-Regular.woff2",
    types: ["gothic"],
  },
  {
    name: "VeronaGothic.woff2    ",
    path: "gothic/VeronaGothic.woff2    ",
    types: ["gothic"],
  },
];

const handwrittenFonts = [
  {
    name: "1IsadoraMBold.woff2",
    path: "handwritten/1IsadoraMBold.woff2",
    types: ["handwritten"],
  },
  {
    name: "AdineKirnberg.woff2",
    path: "handwritten/AdineKirnberg.woff2",
    types: ["handwritten"],
  },
  {
    name: "Adventure.woff2",
    path: "handwritten/Adventure.woff2",
    types: ["handwritten"],
  },
  {
    name: "AlexandraScript.woff2",
    path: "handwritten/AlexandraScript.woff2",
    types: ["handwritten"],
  },
  {
    name: "AllegrettoScriptOne.woff2",
    path: "handwritten/AllegrettoScriptOne.woff2",
    types: ["handwritten"],
  },
  {
    name: "Andantinoscript.woff2",
    path: "handwritten/Andantinoscript.woff2",
    types: ["handwritten"],
  },
  {
    name: "Annabelle.woff2",
    path: "handwritten/Annabelle.woff2",
    types: ["handwritten"],
  },
  {
    name: "Ariston-Normal.woff2",
    path: "handwritten/Ariston-Normal.woff2",
    types: ["handwritten"],
  },
  {
    name: "AuroraScript.woff2",
    path: "handwritten/AuroraScript.woff2",
    types: ["handwritten"],
  },
  {
    name: "AvalonMedium.woff2",
    path: "handwritten/AvalonMedium.woff2",
    types: ["handwritten"],
  },
  {
    name: "BickhamScriptOne.woff2",
    path: "handwritten/BickhamScriptOne.woff2",
    types: ["handwritten"],
  },
  {
    name: "BickhamScriptThree.woff2",
    path: "handwritten/BickhamScriptThree.woff2",
    types: ["handwritten"],
  },
  {
    name: "BikhamCyrScript.woff2",
    path: "handwritten/BikhamCyrScript.woff2",
    types: ["handwritten"],
  },
  {
    name: "Boleroscript.woff2",
    path: "handwritten/Boleroscript.woff2",
    types: ["handwritten"],
  },
  {
    name: "CalligraphiaTwo.woff2",
    path: "handwritten/CalligraphiaTwo.woff2",
    types: ["handwritten"],
  },
  {
    name: "CeremoniousOne.woff2",
    path: "handwritten/CeremoniousOne.woff2",
    types: ["handwritten"],
  },
  {
    name: "CeremoniousTwo.woff2",
    path: "handwritten/CeremoniousTwo.woff2",
    types: ["handwritten"],
  },
  {
    name: "Champignonscript.woff2",
    path: "handwritten/Champignonscript.woff2",
    types: ["handwritten"],
  },
  {
    name: "Corinthia.woff2",
    path: "handwritten/Corinthia.woff2",
    types: ["handwritten"],
  },
  {
    name: "EkaterinaVelikayaOne.woff2",
    path: "handwritten/EkaterinaVelikayaOne.woff2",
    types: ["handwritten"],
  },
  {
    name: "EnglishScript.woff2",
    path: "handwritten/EnglishScript.woff2",
    types: ["handwritten"],
  },
  {
    name: "EseninscriptTwo.woff2",
    path: "handwritten/EseninscriptTwo.woff2",
    types: ["handwritten"],
  },
  {
    name: "HeatherScriptOne.woff2",
    path: "handwritten/HeatherScriptOne.woff2",
    types: ["handwritten"],
  },
  {
    name: "Liana.woff2",
    path: "handwritten/Liana.woff2",
    types: ["handwritten"],
  },
  {
    name: "MartinascriptC.woff2",
    path: "handwritten/MartinascriptC.woff2",
    types: ["handwritten"],
  },
  {
    name: "MonAmourOne-Medium.woff2",
    path: "handwritten/MonAmourOne-Medium.woff2",
    types: ["handwritten"],
  },
  {
    name: "MonAmourTwo-Medium.woff2",
    path: "handwritten/MonAmourTwo-Medium.woff2",
    types: ["handwritten"],
  },
  {
    name: "Monplesirscript.woff2",
    path: "handwritten/Monplesirscript.woff2",
    types: ["handwritten"],
  },
  {
    name: "Ouverturescript.woff2",
    path: "handwritten/Ouverturescript.woff2",
    types: ["handwritten"],
  },
  {
    name: "ZeferinoThree.woff2    ",
    path: "handwritten/ZeferinoThree.woff2    ",
    types: ["handwritten"],
  },
];

const sansSerifFonts = [
  {
    name: "AvenirNextCyr-Medium.woff2",
    path: "sans-serif/AvenirNextCyr-Medium.woff2",
    types: ["sans-serif"],
  },
  {
    name: "Bahnschrift.woff2",
    path: "sans-serif/Bahnschrift.woff2",
    types: ["sans-serif"],
  },
  {
    name: "BebasNeueBold.woff2",
    path: "sans-serif/BebasNeueBold.woff2",
    types: ["sans-serif"],
  },
  {
    name: "BebasNeueBook.woff2",
    path: "sans-serif/BebasNeueBook.woff2",
    types: ["sans-serif"],
  },
  {
    name: "CaviarDreams.woff2",
    path: "sans-serif/CaviarDreams.woff2",
    types: ["sans-serif"],
  },
  {
    name: "CenturyGothic.woff2",
    path: "sans-serif/CenturyGothic.woff2",
    types: ["sans-serif"],
  },
  {
    name: "DRAZKI-Regular.woff2",
    path: "sans-serif/DRAZKI-Regular.woff2",
    types: ["sans-serif"],
  },
  {
    name: "FuturaBT-Bold.woff2",
    path: "sans-serif/FuturaBT-Bold.woff2",
    types: ["sans-serif"],
  },
  {
    name: "FuturaBT-Medium.woff2",
    path: "sans-serif/FuturaBT-Medium.woff2",
    types: ["sans-serif"],
  },
  {
    name: "Haettenschweiler.woff2",
    path: "sans-serif/Haettenschweiler.woff2",
    types: ["sans-serif"],
  },
  {
    name: "LuzSans-Book.woff2",
    path: "sans-serif/LuzSans-Book.woff2",
    types: ["sans-serif"],
  },
  {
    name: "PFBeauSansPro-Thin.woff2",
    path: "sans-serif/PFBeauSansPro-Thin.woff2",
    types: ["sans-serif"],
  },
  {
    name: "PoiretOne-Regular.woff2",
    path: "sans-serif/PoiretOne-Regular.woff2",
    types: ["sans-serif"],
  },
  {
    name: "RosbankSansTTSemibold.woff2",
    path: "sans-serif/RosbankSansTTSemibold.woff2",
    types: ["sans-serif"],
  },
  {
    name: "Rubik-Regular.woff2    ",
    path: "sans-serif/Rubik-Regular.woff2    ",
    types: ["sans-serif"],
  },
];

const serifFonts = [
  {
    name: "BaskervilleBT-Roman.woff2",
    path: "serif/BaskervilleBT-Roman.woff2",
    types: ["serif"],
  },
  {
    name: "BookAntiqua-Bold.woff2",
    path: "serif/BookAntiqua-Bold.woff2",
    types: ["serif"],
  },
  {
    name: "BookAntiqua.woff2",
    path: "serif/BookAntiqua.woff2",
    types: ["serif"],
  },
  { name: "Forum.woff2", path: "serif/Forum.woff2", types: ["serif"] },
  {
    name: "Hamerslag-DEMO.woff2",
    path: "serif/Hamerslag-DEMO.woff2",
    types: ["serif"],
  },
  { name: "Lava-Bold.woff2", path: "serif/Lava-Bold.woff2", types: ["serif"] },
  {
    name: "MADELikesSlab.woff2",
    path: "serif/MADELikesSlab.woff2",
    types: ["serif"],
  },
  { name: "RomanC.woff2", path: "serif/RomanC.woff2", types: ["serif"] },
  { name: "RomanT.woff2", path: "serif/RomanT.woff2", types: ["serif"] },
  {
    name: "YesevaOne.woff2    ",
    path: "serif/YesevaOne.woff2    ",
    types: ["serif"],
  },
];

export const fonts = [
  ...decorFonts,
  ...etnicaFonts,
  ...gothicFonts,
  ...handwrittenFonts,
  ...sansSerifFonts,
  ...serifFonts,
];
