import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import logoSvg from "./logo.svg";

export default function Layout({
  children,
  title,
  handleNext,
  handleBack,
  maxSteps,
  activeStep,
  nextStepDisabled,
}) {
  const theme = useTheme();

  return (
    <>
      <AppBar
        color="primary"
        position="sticky"
        sx={{ backgroundColor: "#212121" }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ mr: "auto" }}>
            {title}
          </Typography>
          <img src={logoSvg} alt="Lazzard" height="20" />
        </Toolbar>
      </AppBar>

      {children}

      {activeStep !== maxSteps - 1 && (
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="bottom"
          activeStep={activeStep}
          sx={{ backgroundColor: "#f5f5f5" }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={nextStepDisabled || activeStep === maxSteps - 1}
              variant="contained"
            >
              Далее
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Назад
            </Button>
          }
        />
      )}
    </>
  );
}
