import getFontName from "./getFontName";

export default function renderFont(font) {
  const name = getFontName(font);

  return `
    @font-face {
      font-family: "${name}";
      src: url(fonts/${font.path});
    }
    `;
}
