import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export default function InputText(props) {
  return (
    <Box sx={{ p: 2 }}>
      <TextField autoFocus fullWidth label="Напишите текст..." {...props} />
    </Box>
  );
}
