import { useState } from "react";
import InputText from "./InputText";
import Layout from "./Layout";
import Confirm from "./Confirm";
import FontList from "./FontList";
import FontTypeList from "./FontTypeList";
import Congratulations from "./Congratulations";
import renderFont from "./renderFont";
import { createTask } from "./api";
import { fontTypes, fonts } from "./constants";

const steps = [
  {
    title: "Выберите шрифт",
    type: "SELECT_FONT",
    validate: (values) => !!values.inputText && values.selectedFonts.length > 0,
  },
  {
    title: "Подтвердите заказ",
    type: "CONFIRM_ORDER",
    validate: (values) =>
      values.inputName &&
      values.inputPhone &&
      values.inputEmail &&
      values.inputProduct &&
      values.inputText &&
      values.selectedFonts.length > 0,
    callback: (values) => createTask(values),
  },
  {
    title: "Заказ создан",
    type: "CONGRATULATIONS",
    validate: () => true,
  },
];

function App() {
  const [selectedFonts, setSelectedFonts] = useState([]);
  const [selectedFontTypes, setSelectedFontTypes] = useState([]);
  const [inputText, setInputText] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputProduct, setInputProduct] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = steps.length;
  const { title, type, validate, callback } = steps[activeStep];

  const values = {
    selectedFonts,
    selectedFontTypes,
    inputText,
    inputName,
    inputPhone,
    inputEmail,
    inputProduct,
  };

  const onChangeInputText = (event) => {
    setInputText(event.target.value);
  };

  const onChangeInputName = (event) => {
    setInputName(event.target.value);
  };

  const onChangeInputPhone = (event) => {
    setInputPhone(event.target.value);
  };

  const onChangeInputEmail = (event) => {
    setInputEmail(event.target.value);
  };

  const onChangeInputProduct = (event) => {
    setInputProduct(event.target.value);
  };

  const onChangeFont = (font) => {
    setSelectedFonts((fonts) => {
      if (fonts.includes(font)) {
        return fonts.filter((f) => f !== font);
      } else {
        return fonts.concat(font);
      }
    });
  };

  const onDeleteFont = (font) => {
    setSelectedFonts((fonts) => {
      return fonts.filter((f) => f !== font);
    });
  };

  const onChangeFontType = (type) => {
    setSelectedFontTypes((types) => {
      if (types.includes(type)) {
        return types.filter((t) => t !== type);
      } else {
        return types.concat(type);
      }
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (callback) {
      callback(values);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const nextStepDisabled = !validate(values);

  const filtererFonts = fonts.filter(
    (font) =>
      font.types.some((f) => selectedFontTypes.includes(f)) ||
      selectedFonts.some((f) => f.name === font.name)
  );

  return (
    <>
      <style>{fonts.map(renderFont)}</style>

      <Layout
        title={title}
        handleNext={handleNext}
        handleBack={handleBack}
        maxSteps={maxSteps}
        activeStep={activeStep}
        nextStepDisabled={nextStepDisabled}
      >
        {type === "SELECT_FONT" && (
          <>
            <InputText onChange={onChangeInputText} value={inputText} />
            <FontTypeList
              fontTypes={fontTypes}
              selectedFontTypes={selectedFontTypes}
              onChangeFontType={onChangeFontType}
            />
            <FontList
              inputText={inputText}
              fonts={filtererFonts}
              selectedFonts={selectedFonts}
              onChangeFont={onChangeFont}
            />
          </>
        )}
        {type === "CONFIRM_ORDER" && (
          <Confirm
            onChangeInputText={onChangeInputText}
            onChangeInputName={onChangeInputName}
            onChangeInputPhone={onChangeInputPhone}
            onChangeInputEmail={onChangeInputEmail}
            onChangeInputProduct={onChangeInputProduct}
            inputText={inputText}
            inputName={inputName}
            inputPhone={inputPhone}
            inputEmail={inputEmail}
            inputProduct={inputProduct}
            fonts={fonts}
            selectedFonts={selectedFonts}
            onDeleteFont={onDeleteFont}
          />
        )}
        {type === "CONGRATULATIONS" && <Congratulations />}
      </Layout>
    </>
  );
}

export default App;
