import React from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function Congratulations() {
  const { width, height } = useWindowSize();

  return (
    <>
      <Confetti width={width} height={height} />
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" sx={{ mt: 10 }}>
          Спасибо за заказ!
        </Typography>
      </Box>
    </>
  );
}
