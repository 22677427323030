import * as React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

export default function FontTypeList({
  fontTypes,
  selectedFontTypes,
  onChangeFontType,
}) {
  return (
    <Box sx={{ pl: 2, pr: 2 }}>
      <Grid container spacing={1}>
        {fontTypes.map((type) => {
          const selected = selectedFontTypes.includes(type.id);

          return (
            <Grid item key={type.id}>
              <Chip
                label={type.title}
                color={selected ? "primary" : "default"}
                onClick={(event) => onChangeFontType(type.id)}
                sx={{ fontFamily: `"${type.fontName}"`, fontSize: "1.2rem" }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
