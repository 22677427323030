import React from "react";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import getFontName from "./getFontName";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Confirm({
  selectedFonts,
  onDeleteFont,
  inputText,
  onChangeInputText,
  inputName,
  onChangeInputName,
  inputPhone,
  onChangeInputPhone,
  inputEmail,
  onChangeInputEmail,
  inputProduct,
  onChangeInputProduct,
}) {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Имя"
              required
              value={inputName}
              onChange={onChangeInputName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="phone"
              label="Телефон"
              required
              value={inputPhone}
              onChange={onChangeInputPhone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="email"
              label="E-Mail"
              required
              value={inputEmail}
              onChange={onChangeInputEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Название изделия"
              required
              value={inputProduct}
              onChange={onChangeInputProduct}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Текст"
              value={inputText}
              onChange={onChangeInputText}
            />
          </Grid>
        </Grid>
      </Box>
      <List
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
        }}
        component="nav"
      >
        {selectedFonts.map((font) => {
          const name = getFontName(font);

          return (
            <ListItem
              divider
              key={font.name}
              secondaryAction={
                selectedFonts.length > 1 && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(event) => onDeleteFont(font)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText
                secondary={name}
                primary={inputText}
                primaryTypographyProps={{
                  fontFamily: name,
                  fontSize: "1.4rem",
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
