import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import getFontName from "./getFontName";

export default function FontList({
  inputText,
  fonts,
  selectedFonts,
  onChangeFont,
}) {
  return (
    <List
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
      component="nav"
    >
      {fonts.map((font) => {
        const name = getFontName(font);
        const selected = selectedFonts.includes(font);
        return (
          <ListItemButton
            divider
            selected={selected}
            key={font.name}
            onClick={(event) => onChangeFont(font)}
          >
            <ListItemIcon>
              {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText
              secondary={name}
              primary={inputText || "Example"}
              primaryTypographyProps={{
                fontFamily: `"${name}"`,
                fontSize: "1.9rem",
              }}
            />
          </ListItemButton>
        );
      })}
    </List>
  );
}
